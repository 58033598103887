/* eslint-disable unused-imports/no-unused-imports */
import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div``;

const header = styled.div`
  display: flex;
  justify-content: space-between;

  > .updated-at {
    ${setFontStyle("SubHead3")};
    color: ${TEXT_COLOR.black_2};
  }
`;

const tableContainer = styled.div`
  em.confirmed-charge {
    color: ${COLOR.primary_600};
  }

  .exceeded-duration {
    color: ${COLOR.point_400};
  }
`;

export default {
  container,
  header,
  tableContainer,
};
