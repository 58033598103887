import { useCallback, useMemo } from "react";

import { FreeTimeContainer } from "@sellernote/_shared/src/types/forwarding/bid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { replaceEmptyToDash } from "@sellernote/_shared/src/utils/common/string";
import { calculateFreeTimeDate } from "@sellernote/_shared/src/utils/forwarding/freeTime";

export default function useFreeTimeTableData({
  containers,
  isCombined,
  filteredContainerNoList,
}: {
  containers?: Array<FreeTimeContainer>;
  isCombined?: boolean;
  filteredContainerNoList: string[];
}) {
  // 의뢰당 컨테이너 수가 적고 페이지네이션이 없어서 프론트에서 필터링하기로 합의.
  const containerInfoList = useMemo(() => {
    // filter 에서 선택한 containerNo 가 없으면, 전체 리스트를 모두 보여준다. (default)
    if (!filteredContainerNoList.length) return containers;

    return containers?.filter(({ containerNo }) =>
      filteredContainerNoList.includes(containerNo)
    );
  }, [filteredContainerNoList, containers]);

  const toFormattedOccurrenceDate = (date: Date | null) =>
    replaceEmptyToDash(toFormattedDate(date, "YY-MM-DD"));

  const dateAndPriceByStatus = useCallback(
    (containerInfo: FreeTimeContainer) => {
      const {
        inboundTime,
        outboundTime,
        returnTime,

        storageFreeTimeDay,
        storageCharge,

        combinedFreeTimeDay,
        combinedCharge,

        demurrageFreeTimeDay: DEMFreeTimeDay,
        demurrageCharge: DEMCharge,

        detentionFreeTimeDay: DETFreeTimeDay,
        detentionCharge: DETCharge,

        /** 계산이 된 프리타임 기한 */
        storageLFD,
        demurrageLFD,
        detentionLFD,
        combinedLFD,
      } = containerInfo;

      const LFD = (() => {
        /**
         *
         * @param category 화면에 표시되는 항목. (DEM, DET, Storage, DET+DEM)
         * @param freeTimeDay 반입 후 n일을 표기하는 프리타임 예상일.
         * @param dates 위 calculateDate 함수로 계산한 날짜들.
         * @returns 각 조건에 따라 화면에 표시되는 포맷에 맞춘 날짜 형식.
         */
        const toLFDFormat = (
          category: string,
          freeTimeDay: number,
          dates: {
            occurrenceDate: Date | null;
            freeTimeDate: string;
            exceededDuration: number;
          }
        ) => {
          const { freeTimeDate, exceededDuration } = dates;

          const isOverdue = exceededDuration > 0;

          return (
            <div className={`${isOverdue && "exceeded-duration"}`}>
              {category}:{" "}
              {freeTimeDate ? freeTimeDate : `반입 후 ${freeTimeDay}일`}
              {isOverdue && ` (${exceededDuration}일 초과)`}
            </div>
          );
        };

        return {
          storage: toLFDFormat(
            "Storage",
            storageFreeTimeDay,
            calculateFreeTimeDate(inboundTime, outboundTime, storageLFD)
          ),
          DEM: toLFDFormat(
            "DEM",
            DEMFreeTimeDay,
            calculateFreeTimeDate(inboundTime, outboundTime, demurrageLFD)
          ),
          DET: toLFDFormat(
            "DET",
            DETFreeTimeDay,
            calculateFreeTimeDate(outboundTime, returnTime, detentionLFD) // DET 만 발생일 조건이 다름.
          ),
          DETAndDEM: toLFDFormat(
            "DET + DEM",
            combinedFreeTimeDay,
            calculateFreeTimeDate(inboundTime, returnTime, combinedLFD)
          ),
        };
      })();

      const charge = (() => {
        if (!inboundTime)
          return {
            storage: "-원",
            DEM: "-원",
            DETAndDEM: "-원",
            DET: "-원",
          };

        const toPriceFormat = (price: number, isConfirmed: boolean) => {
          return (
            <>
              {price ? `${toThousandUnitFormat(price)}원` : "초과비용없음"}

              {isConfirmed && (
                <em className="confirmed-charge">&nbsp;(확정)</em>
              )}
            </>
          );
        };

        return {
          storage: toPriceFormat(storageCharge, !!outboundTime),
          DEM: toPriceFormat(DEMCharge, !!outboundTime),
          DETAndDEM: toPriceFormat(combinedCharge, !!returnTime),
          DET: toPriceFormat(DETCharge, !!returnTime),
        };
      })();

      return [
        {
          status: "반입",
          date: toFormattedOccurrenceDate(inboundTime),
          LFD: "-",
          price: "-",
        },
        {
          status: "반출",
          date: toFormattedOccurrenceDate(outboundTime),
          LFD: (
            <>
              {!isCombined && (
                <>
                  {LFD.DEM}
                  <br />
                </>
              )}

              {LFD.storage}
            </>
          ),
          price: (
            <>
              {!isCombined && (
                <>
                  {charge.DEM}
                  <br />
                </>
              )}

              {charge.storage}
            </>
          ),
        },
        {
          status: "반납",
          date: toFormattedOccurrenceDate(returnTime),
          LFD: isCombined ? LFD.DETAndDEM : LFD.DET,
          price: isCombined ? charge.DETAndDEM : charge.DET,
        },
      ];
    },
    [isCombined]
  );

  const rows = useMemo(() => {
    if (!containerInfoList) return [];

    return containerInfoList
      .map((containerInfo) => {
        // 하나의 컨테이너 정보에 반입, 반출, 반납 세가지 status 의 경우로 나뉘어진 리스트.
        const listByStatus = dateAndPriceByStatus(containerInfo);

        return listByStatus.map((item) => {
          return {
            id: containerInfo.containerNo,
            containerNo: containerInfo.containerNo,
            ...item,
          };
        });
      })
      .flat();
  }, [dateAndPriceByStatus, containerInfoList]);

  return { rows };
}
