import { createColumnHelper } from "@tanstack/react-table";

type Container = {
  containerNo: string;
  status: string;
  date: string;
  LFD: React.ReactNode;
  price: React.ReactNode;
};

export type CommonKeys = "containerNo" | "status" | "date" | "LFD" | "price";

export default function useColumns() {
  const columnHelper = createColumnHelper<Container>();

  const columns = [
    columnHelper.accessor("containerNo", {
      id: "containerNo",
      header: "컨테이너 NO.",
      cell: (info) => info.getValue(),
      meta: { hasFilter: true },
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: "상태",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: "발생일",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("LFD", {
      id: "LFD",
      header: "LFD(프리타임 기한)",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: "발생요금",
      cell: (info) => info.getValue(),
    }),
  ];

  return { columns };
}
