import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div<{ backgroundColor?: keyof typeof COLOR }>`
  width: 100%;
  padding: 22px 16px 8px;

  ${({ backgroundColor }) =>
    backgroundColor
      ? css`
          background-color: ${COLOR[backgroundColor]};
        `
      : css`
          background-color: transparent;
        `}
`;

const schedule = styled.div<{
  processingWidth: number;
  isTransportComplete: boolean;
  isShipdaAdmin: boolean;
}>`
  position: relative;

  /** 점선 라인 */
  &::after {
    z-index: 0;
    content: "";
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 0;
    width: 100%;
    height: 2px;

    ${({ isShipdaAdmin }) => {
      if (isShipdaAdmin) {
        return css`
          background: transparent
            url("/images/shipment/icon_infographic_dashed_horizontal.svg") space;
        `;
      }

      return css`
        background: transparent
          url("/assets/images/shipment/icon_infographic_dashed_horizontal.svg")
          space;
      `;
    }}
    transform: translateY(-50%);

    ${mobile(css<{ isShipdaAdmin: boolean }>`
      top: 12px;
      left: 4px;
      width: 2px;
      height: calc(100% - 34px);
      ${({ isShipdaAdmin }) => {
        if (isShipdaAdmin) {
          return css`
            background: transparent
              url("/images/shipment/icon_infographic_dashed_vertical.svg") space;
          `;
        }

        return css`
          background: transparent
            url("/assets/images/shipment/icon_infographic_dashed_vertical.svg")
            space;
        `;
      }}
      transform: translateY(0);
    `)}
  }

  /** 진행중 라인 */
  &::before {
    z-index: 1;
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 5px;
    width: ${({ processingWidth }) => `${processingWidth}%`};
    border-top: 2px solid ${COLOR.grayScale_400};
    transform: translateY(-50%);

    ${({ processingWidth, isTransportComplete }) =>
      mobile(css`
        z-index: 1;
        left: 4px;
        top: 5px;
        width: 2px;
        height: ${isTransportComplete
          ? "calc(100% - 34px)"
          : `${processingWidth}%`};
        border-top: 0;
        border-left: 2px solid ${COLOR.grayScale_400};
        transform: rotateY(0);
      `)}
  }
`;

const portList = styled.div`
  z-index: 2;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${mobile(css`
    flex-direction: column;
  `)}
`;

const timeLeft = styled.span<{ isDone: boolean }>`
  padding-top: 8px;
  max-width: 34px;
  white-space: nowrap;
  ${setFontStyle("Body5")};
  color: ${TEXT_COLOR.black_3};

  ${({ isDone }) =>
    isDone &&
    css`
      /* (예상) 텍스트가 붙은 경우 전체를 기준으로 라인 가운데에 위치하기 위해 text-indent 사용 */
      text-indent: -8px;
    `}

  ${mobile(css`
    text-indent: 0;
    padding: 8px 0 25px 24px;

    &:not(:first-of-type) {
      padding: 22px 0 30px 24px;
    }
  `)}

  & > .desc {
    ${setFontStyle("Body5")};
    color: ${TEXT_COLOR.black_4};
  }
`;

const portGroup = styled.div<{
  textPosition: "left" | "right" | "center";
  isVisibleDate: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 0 0 auto;
  max-width: 34px;

  /** Dot 아이콘 수직정렬 */
  & > .dot {
    width: 10px;
    height: 10px;

    & > svg {
      vertical-align: top;
    }
  }

  /** Dot과 텍스트 정렬 */
  ${({ textPosition }) => {
    if (textPosition === "left") {
      return css`
        align-items: flex-start;
        text-align: left;
      `;
    }

    if (textPosition === "right") {
      return css`
        align-items: flex-end;
        text-align: right;
      `;
    }

    return css`
      align-items: center;
      text-align: center;
    `;
  }}

  ${mobile(css`
    flex-direction: row;
    align-items: center;
    gap: 8px;
  `)}

  ${({ isVisibleDate }) =>
    isVisibleDate &&
    mobile(css`
      align-items: flex-start;
      & > .dot {
        margin-top: 4px;
      }
    `)}

  & > .port-info {
    .port {
      display: flex;
      align-items: center;
      gap: 2px;
      white-space: nowrap;

      ${setFontStyle("Body4")};
      color: ${TEXT_COLOR.black_2};

      ${({ textPosition }) =>
        textPosition === "right" &&
        css`
          justify-content: flex-end;
        `}

      ${mobile(css`
        justify-content: flex-start;
      `)}
    }

    .date {
      ${setFontStyle("Body5")}
      color: ${TEXT_COLOR.black_3};
      white-space: nowrap;
    }

    ${mobile(css`
      min-width: 74px;
    `)}
  }
`;

const realtimePosition = styled.div<{
  position: number;
  isAirFreightType: boolean;
  isMobile: boolean;
  isTransportComplete: boolean;
}>`
  z-index: 3;
  position: absolute;
  top: ${({ isAirFreightType }) => (isAirFreightType ? "-15px" : "-13px")};
  text-align: right;

  ${({ position, isMobile, isAirFreightType, isTransportComplete }) => {
    if (isTransportComplete) {
      return isMobile
        ? css`
            top: calc(100% - 36px);
            left: -2px;
          `
        : css`
            left: calc(100% - 25px);
          `;
    }

    if (isMobile) {
      return position
        ? css`
            top: calc(${position}% - 12px);
            left: -2px;
          `
        : css`
            top: 0;
            left: -2px;
          `;
    }

    if (position) {
      return isAirFreightType
        ? css`
            left: calc(${position}% - 8px);
          `
        : css`
            left: calc(${position}% - 4px);
          `;
    }

    return css`
      left: 0px;
    `;
  }}
`;

export default {
  container,
  schedule,
  portList,
  portGroup,
  timeLeft,
  realtimePosition,
};
